
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="Activities"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Add new activity"
					icon="plus"
					@click="goToActivityAdd"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="table_filters"
			>
				<template #extra_filters>
					<PeriodPickerComponent
						v-model="displayed_period"
						input-id="displayed-period-picker"
						name="displayed_period_picker"
						required="false"
						label="Display activities in a specific period:"
						default-period-type="week"
					/>
				</template>
			</FiltersAndSearchComponent>
			<PaginatedTableComponent
				:columns="table_columns"
				:data="table_data"
				:active-filters="active_filters"
				@edit-activity="goToActivityEdit"
			/>
		</div>
	</LoadingStateComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import PeriodPickerComponent from '../../components/PeriodPickerComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_activities_by_period from '../../graphql/query/ActivitiesByPeriod.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';
import gql_query_all_managers from '../../graphql/query/AllManagers.gql';

import moment from 'moment';

import {
	formatTimeslot,
	formatDateHuman,
	convertCraftEntriesToSelectOptions
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	ACTIVITY_TYPE_MEMBER_EVENT,
	ACTIVITY_TYPE_OPEN_EVENT,
	ACTIVITY_TYPE_PUBLIC_EVENT,
	ACTIVITY_TYPE_TRAINING,
	ACTIVITY_TYPE_ORIENTATION,
	ACTIVITY_TYPE_NEW_MEMBER_INDUCTION,
	ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
		PeriodPickerComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const { activity_location, activity_type, activity_lead, displayed_period } = storeToRefs( filter_store );
		return { activity_location, activity_type, activity_lead, displayed_period };
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			show_export_modal: false,
			breadcrumbs: [
				{ label: 'Bookings' },
				{ label: 'Activities' },
			],
			table_columns: [
				{
					label: 'Activity title',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Attendees',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Waiting',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Time',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Activity lead',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-activity',
				},
			],
			table_filters: [
				{
					slug: 'activity_location',
					options: [
						{ value: '', label: 'All locations' },
					],
				},
				{
					slug: 'activity_type',
					options: [
						{ value: '', label: 'All activity types' },
						{ value: ACTIVITY_TYPE_MEMBER_EVENT, label: 'Member event' },
						{ value: ACTIVITY_TYPE_OPEN_EVENT, label: 'UCL event' },
						{ value: ACTIVITY_TYPE_PUBLIC_EVENT, label: 'Public event' },
						{ value: ACTIVITY_TYPE_TRAINING, label: 'Training' },
						{ value: ACTIVITY_TYPE_ORIENTATION, label: 'Orientation' },
						{ value: ACTIVITY_TYPE_NEW_MEMBER_INDUCTION, label: 'New member induction' },
					],
				},
				{
					slug: 'activity_lead',
					options: [
						{ value: '', label: 'All activity leads' },
					],
				},
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'activity_location',
					value: this.activity_location
				},
				{
					name: 'activity_type',
					value: this.activity_type
				},
				{
					name: 'activity_lead',
					value: this.activity_lead
				},
			];
		}
	},
	watch: {
		displayed_period() {
			this.getTableData();
		},
	},
	async mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_locations ).then( ( response ) => {
			this.table_filters[0].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );
		this.$craftGraphqlApiClient.query( gql_query_all_managers ).then( ( response ) => {
			this.table_filters[2].options.push( ...convertCraftEntriesToSelectOptions( response.data.users, ( e ) => e.fullName ) );
		} );

		await this.getTableData();
		this.loading_state = LOADING_STATE_NONE;
	},
	methods: {
		getTableData() {
			return this.$craftGraphqlApiClient.query(
				gql_query_activities_by_period,
				{
					from: moment( this.displayed_period.start_date ).format( 'YYYY-MM-DD' ),
					until: moment( this.displayed_period.end_date ).format( 'YYYY-MM-DD' ),
				}
			).then( ( response ) => {
				this.table_data = response.data.activitiesByPeriod.map( activity => {
					let date_string = formatDateHuman(
						new Date( activity.start_date )
					);
					if ( activity.end_date ) {
						date_string += ' - ' + formatDateHuman(
							new Date( activity.end_date )
						);
					}
					const has_attendees = activity.typeHandle !== ACTIVITY_TYPE_PUBLIC_EVENT
						&& activity.typeHandle !== ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE
					;
					return [
						{
							visible: {
								text: activity.title,
								detail: this.$craftSectionData.getEntryTypeName(
									'activities',
									activity.typeId
								),
							},
							routeable: activity.id,
							filterable: {
								slug: 'activity_type',
								values: [activity.typeHandle],
							},
						},
						{
							visible: this.getLocationColumnData( activity ),
							filterable: {
								slug: 'activity_location',
								values: activity.location ? activity.location.map( location => location.id ) : [],
							}
						},
						{
							visible: has_attendees ? `${activity.attendee_count} / ${activity.capacity}` : '-',
						},
						{
							visible: has_attendees ? `${activity.waiting_count} / ${activity.waiting_list_capacity}` : '-',
						},
						{
							visible: date_string,
						},
						{
							visible: formatTimeslot(
								new Date( activity.start_time ),
								new Date( activity.end_time )
							),
						},
						{
							visible: activity.activity_lead && activity.activity_lead.length ? activity.activity_lead[0].fullName : '-',
							filterable: {
								slug: 'activity_lead',
								values: activity.activity_lead && activity.activity_lead.length ? [activity.activity_lead[0].id] : [],
							},
						},
						null,
					];
				} );
			} );
		},
		getLocationColumnData( activity ) {
			if ( !activity.location || !activity.location.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: activity.location[0].title,
				detail: activity.location.length > 1 ? activity.location[1].title : ''
			};
		},
		goToActivityEdit( row ) {
			this.$router.push( {
				name: 'bookings__edit_activity',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToActivityAdd() {
			this.$router.push( {
				name: 'bookings__add_activity',
			} );
		},
	},
};

</script>
