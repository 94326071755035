
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
			:before-tabs="true"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonDropdownComponent
					label="Actions"
				>
					<ButtonComponent
						label="Warn member"
						type="dropdown-item"
						icon="incident"
						@click="show_warn_modal = true"
					/>
					<ButtonComponent
						label="Impersonate member"
						type="dropdown-item"
						icon="user"
						@click="show_impersonate_modal = true"
					/>
					<ButtonComponent
						label="Delete member"
						type="dropdown-item"
						icon="delete"
						@click="show_delete_modal = true"
					/>
				</ButtonDropdownComponent>
			</template>
		</HeaderComponent>
		<TabsComponent
			v-if="content_ready"
			:tabs="tabs"
		>
			<template #overview>
				<div
					class="page-content page-content--form"
				>
					<h2 class="h-2">
						Overview
					</h2>
					<div
						v-if="member_warnings_data.length > 0"
						class="notice notice--error notice--inline"
					>
						<p class="notice__message">
							This member has received {{ member_warnings_data.length }} written warnings
						</p>
						<p><a href="#warnings">View warnings</a></p>
					</div>
					<p class="p">
						Date joined: {{ formatDateHuman( model_data.dateCreated ) }}
					</p>
					<p
						v-if="model_data.member_activity === 'active'"
						class="p status-marker status-marker--on"
					>
						Account activity: Active
					</p>
					<p
						v-else
						class="p status-marker status-marker--off"
					>
						Account activity: Inactive
					</p>

					<div class="form__set">
						<div class="flex flex--align-end grid grid--small">
							<div class="grid__item">
								<InputComponent
									v-model="model_data.member_status"
									label="Account status"
									type="select"
									input-id="member_statuses"
									:options="member_statuses"
									:errors="model_errors.member_status"
									:no-margin="true"
								/>
							</div>
							<div class="grid__item">
								<ButtonComponent
									label="Update status"
									@click="updateMemberStatus"
								/>
							</div>
						</div>
					</div>

					<template v-if="member_warnings_data.length > 0">
						<h2
							id="warnings"
							class="h-4"
						>
							Warnings
						</h2>
						<PaginatedTableComponent
							:columns="member_warnings_table_columns"
							:data="member_warnings_data"
							@remove-warning="removeWarning"
						/>
						<div class="form__set">
							<ButtonComponent
								label="Reset warnings to zero"
								@click="resetWarnings"
							/>
						</div>
					</template>

					<h2 class="h-4">
						New member induction
					</h2>
					<PaginatedTableComponent
						v-if="member_inductions_data.length > 0"
						:columns="member_inductions_table_columns"
						:data="member_inductions_data"
						@revoke-induction-status="revokeInductionStatus"
					/>
					<div
						v-else
						class="form__set"
					>
						<ButtonComponent
							label="Induct member"
							@click="show_induct_member_modal = true"
						/>
					</div>

					<h2 class="h-4">
						Location access
					</h2>
					<PaginatedTableComponent
						:columns="member_location_grants_table_columns"
						:data="member_location_grants_data"
						@revoke-location-access="revokeLocationGrant"
					/>
					<div class="flex grid grid--small">
						<div class="grid__item">
							<InputComponent
								v-model="location_to_grant"
								label="Location"
								type="select"
								input-id="location_to_grant"
								:options="grantable_locations"
								:hide-label="true"
								:select-use-optgroups="true"
							/>
						</div>
						<div class="grid__item">
							<ButtonComponent
								label="Grant location access"
								@click="grantLocationAccess"
							/>
						</div>
					</div>

					<h2 class="h-4">
						Tool access
					</h2>
					<PaginatedTableComponent
						:columns="member_tool_class_grants_table_columns"
						:data="member_tool_class_grants_data"
						@revoke-tool-access="revokeToolClassGrant"
					/>
					<div class="flex grid grid--small">
						<div class="grid__item">
							<InputComponent
								v-model="tool_class_to_grant"
								label="Tool class"
								type="select"
								input-id="tool_class_to_grant"
								:options="grantable_tool_classes"
								:hide-label="true"
								:select-use-optgroups="true"
							/>
						</div>
						<div class="grid__item">
							<ButtonComponent
								label="Grant tool class access"
								@click="grantToolClassAccess"
							/>
						</div>
					</div>
				</div>
			</template>
			<template #member_details>
				<div class="page-content page-content--form">
					<h2 class="h-2">
						Personal information
					</h2>
					<div class="flex flex--wrap grid grid--small">
						<div class="grid__item a6-12">
							<InputComponent
								v-model="model_data.firstName"
								label="First name"
								type="text"
								input-id="firstName"
								:errors="model_errors.firstName"
							/>
						</div>
						<div class="grid__item a6-12">
							<InputComponent
								v-model="model_data.lastName"
								label="Last name"
								type="text"
								input-id="lastName"
								:errors="model_errors.lastName"
							/>
						</div>
					</div>
					<InputComponent
						v-model="model_data.gender_identity"
						label="Gender identity"
						type="checkboxgroup"
						input-id="gender_identity"
						:options="gender_identities"
						:errors="model_errors.gender_identity"
					/>
					<InputComponent
						v-if="model_data.gender_identity && model_data.gender_identity.includes( 'let_me_type' )"
						v-model="model_data.gender_identity_other"
						label="Gender identity (other)"
						type="text"
						input-id="gender_identity_other"
						:errors="model_errors.gender_identity_other"
					/>

					<h2 class="h-2">
						Contact details
					</h2>
					<InputComponent
						v-model="model_data.email"
						label="Email"
						type="email"
						input-id="email"
						:errors="model_errors.email"
					/>
					<InputComponent
						v-model="model_data.mobile_phone_number"
						label="Mobile phone number"
						type="tel"
						input-id="mobile_phone_number"
						:errors="model_errors.mobile_phone_number"
					/>

					<h2 class="h-2">
						Academic details
					</h2>
					<div class="flex flex--wrap grid grid--small">
						<div class="grid__item a6-12">
							<InputComponent
								v-model="model_data.membership_type"
								label="Membership type"
								type="select"
								input-id="membership_type"
								:options="membership_types"
								:errors="model_errors.membership_type"
							/>
						</div>
						<div
							v-if="isStudentMember()"
							class="grid__item a6-12"
						>
							<InputComponent
								v-model="model_data.degree_type"
								label="Degree type"
								type="select"
								input-id="degree_type"
								:options="degree_types"
								:errors="model_errors.degree_type"
							/>
						</div>
						<div
							v-if="isStudentMember()"
							class="grid__item a6-12"
						>
							<InputComponent
								v-model="model_data.degree_name_position"
								label="Degree name / position"
								type="text"
								input-id="degree_name_position"
								:errors="model_errors.degree_name_position"
							/>
						</div>
						<div class="grid__item a6-12">
							<InputComponent
								v-model="model_data.faculty"
								label="Faculty"
								type="select"
								input-id="faculty"
								:options="faculties"
								:errors="model_errors.faculty"
							/>
						</div>
						<div
							v-if="!isStudentMember()"
							class="grid__item a6-12"
						>
							<InputComponent
								v-model="model_data.role"
								label="Role"
								type="text"
								input-id="role"
								:errors="model_errors.role"
							/>
						</div>
					</div>
					<div class="flex flex--wrap grid grid--small">
						<div class="grid__item a6-12">
							<InputComponent
								v-model="model_data.upi_code"
								label="UPI code"
								type="text"
								input-id="upi_code"
								:errors="model_errors.upi_code"
							/>
						</div>
						<div class="grid__item a6-12">
							<div class="flex flex--wrap grid grid--small">
								<div class="grid__item a6-12 g6-6">
									<InputComponent
										v-model="model_data.card_expiry_month"
										label="Card expiry month"
										type="select"
										input-id="card_expiry_month"
										:options="months"
										:errors="model_errors.card_expiry_month"
									/>
								</div>
								<div class="grid__item a6-12 g6-6">
									<InputComponent
										v-model="model_data.card_expiry_year"
										label="Card expiry year"
										type="select"
										input-id="card_expiry_year"
										:options="years"
										:errors="model_errors.card_expiry_year"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="flex flex--wrap grid grid--small">
						<div class="grid__item">
							<ButtonComponent
								label="Save changes"
								@click="saveMemberData"
							/>
						</div>
						<div
							v-if="show_entry_actions"
							class="grid__item"
						>
							<ButtonComponent
								label="Send password reset email"
								@click="show_password_reset_modal = true"
							/>
						</div>
						<div class="grid__item">
							<ButtonComponent
								label="Cancel"
								type="outline"
								@click="$router.push( { name: 'members__all_members' } )"
							/>
						</div>
					</div>
				</div>
			</template>
			<template #booking_history>
				<div class="page-content page-content--table">
					<FiltersAndSearchComponent
						:filters="bookings_table_filters"
					/>
					<PaginatedTableComponent
						:columns="bookings_table_columns"
						:data="bookings_data"
						:active-filters="active_filters_bookings"
						@go-to-tool-or-activity="row => goToToolOrActivity( row )"
					/>
				</div>
			</template>
			<template #audit_log>
				<div class="page-content page-content--table">
					<FiltersAndSearchComponent
						:filters="audit_table_filters"
					/>
					<PaginatedTableComponent
						:columns="audit_table_columns"
						:data="audit_data"
						:active-filters="active_filters_audit"
					/>
				</div>
			</template>
		</TabsComponent>
	</LoadingStateComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Warn member"
		:show="show_warn_modal"
		@close-modal="show_warn_modal = false"
	>
		<IssueWarningComponent
			v-if="content_ready"
			:member-id="model_data.id"
			:member-name="model_data.fullName"
			:warning-count="model_data.warnings.length"
			@warning-issued="refreshMemberData"
			@close-modal="show_warn_modal = false"
		/>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Impersonate member"
		:show="show_impersonate_modal"
		@close-modal="show_impersonate_modal= false"
	>
		<p class="p">
			Impersonation will log you into this member’s account and take you to the members area. For security reasons, you must log out and back in to access your manager’s account again.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Impersonate"
				:padded="true"
				@click="impersonateMember"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_impersonate_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Delete member"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this member? After confirming below the data for this member will be permanently removed and this action cannot be undone.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteMember"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Send password reset email"
		:show="show_password_reset_modal"
		@close-modal="show_password_reset_modal = false"
	>
		<p class="p">
			The member will be emailed a link to reset their password.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Send email"
				@click="sendPasswordResetEmail"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_password_reset_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		heading="Induct member"
		:show="show_induct_member_modal"
		@close-modal="show_induct_member_modal = false"
	>
		<InputComponent
			v-model="induction_location"
			label="Induction location"
			sub-label="If set, member will also be granted access to this location."
			type="select"
			input-id="induction_location"
			:options="grantable_locations"
			placeholder="None"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Induct member"
				@click="inductMember()"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_induct_member_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonDropdownComponent from '../../components/ButtonDropdownComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import TabsComponent from '../../components/TabsComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import IssueWarningComponent from '../../components/IssueWarningComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_location_grants_by_grantee from '../../graphql/query/LocationGrantsByGrantee.gql';
import gql_query_tool_class_grants_by_grantee from '../../graphql/query/ToolClassGrantsByGrantee.gql';
import gql_query_member from '../../graphql/query/Member.gql';
import gql_query_all_user_actions from '../../graphql/query/AllUserActions.gql';
import gql_query_grantable_tool_classes from '../../graphql/query/GrantableToolClasses.gql';
import gql_query_grantable_locations from '../../graphql/query/GrantableLocations.gql';

import {
	months_select_options,
	getYearsSelectOptions,
	formatDate,
	formatDateHuman,
	formatTime,
	formatTimeslot,
	formatAuditLogMessage,
	scrollFirstErrorIntoView,
	convertCraftEntriesToSelectOptgroups,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_ACTION_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	MEMBER_STATUS_OPEN,
	MEMBER_STATUS_LOCKED,
	ACTIVITY_TYPE_MEMBER_EVENT,
	ACTIVITY_TYPE_OPEN_EVENT,
	ACTIVITY_TYPE_PUBLIC_EVENT,
	ACTIVITY_TYPE_TRAINING,
	ACTIVITY_TYPE_ORIENTATION,
	ACTIVITY_TYPE_NEW_MEMBER_INDUCTION,
	ACTIVITY_BOOKING_TYPE_WAITLIST,
	TOOL_CLASS_TYPE_PARENT_CLASS,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonDropdownComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent,
		TabsComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
		IssueWarningComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const {
			booking_type,
			audit_type,
		} = storeToRefs( filter_store );
		return {
			booking_type,
			audit_type,
		};
	},
	data() {
		let visible_tabs;
		if ( this.$route.name === 'members__add_member' ) {
			visible_tabs = [
				{ name: 'member_details', label: 'Member details' },
			];
		} else {
			visible_tabs = [
				{ name: 'overview', label: 'Overview' },
				{ name: 'member_details', label: 'Member details' },
				{ name: 'booking_history', label: 'Booking history' },
				{ name: 'audit_log', label: 'Audit log' },
			];
		}
		return {
			loading_state: LOADING_STATE_INITIAL,
			tabs: visible_tabs,

			member_warnings_data: [],
			member_warnings_table_columns: [
				{
					label: 'Authorised By',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date given',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Reason',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Remove',
					button_variant: 'danger',
					action_event: 'remove-warning',
				},
			],

			member_inductions_data: [],
			member_inductions_table_columns: [
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Authorised By',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date inducted',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date agreement signed',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Revoke',
					button_variant: 'danger',
					action_event: 'revoke-induction-status',
				},
			],

			member_location_grants_data: [],
			member_location_grants_table_columns: [
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Granted By',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Revoke',
					button_variant: 'danger',
					action_event: 'revoke-location-access',
				},
			],
			grantable_locations: [],
			location_to_grant: '',
			induction_location: '',

			member_tool_class_grants_data: [],
			member_tool_class_grants_table_columns: [
				{
					label: 'Tool Class',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Granted By',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Revoke',
					button_variant: 'danger',
					action_event: 'revoke-tool-access',
				},
			],
			grantable_tool_classes: [],
			tool_class_to_grant: '',

			model_data: {},
			model_errors: {},
			member_statuses: [
				{ value: MEMBER_STATUS_OPEN, label: 'Open' },
				{ value: MEMBER_STATUS_LOCKED, label: 'Locked' },
			],
			gender_identities: [
				{ value: 'woman', label: 'Woman' },
				{ value: 'man', label: 'Man' },
				{ value: 'transgender', label: 'Transgender' },
				{ value: 'non_binary', label: 'Non-Binary' },
				{ value: 'let_me_type', label: 'Let me type...' },
				{ value: 'prefer_not_to_say', label: 'Prefer not to say' },
			],
			membership_types: [
				{ value: 'student_undergraduate', label: 'Student Undergraduate' },
				{ value: 'student_postgraduate', label: 'Student Postgraduate' },
				{ value: 'staff_academic', label: 'Staff Academic' },
				{ value: 'staff_professional_services', label: 'Staff Professional Services' },
			],
			degree_types: [
				{ value: 'ba', label: 'BA' },
				{ value: 'bsc', label: 'BSc' },
				{ value: 'meng', label: 'MEng' },
				{ value: 'masters', label: 'Masters' },
			],
			faculties: [
				{ value: 'arts_humanities', label: 'Arts & Humanities' },
			],
			months: months_select_options,
			years: getYearsSelectOptions( 0, 10 ),

			show_warn_modal: false,
			show_impersonate_modal: false,
			show_delete_modal: false,
			show_password_reset_modal: false,
			show_induct_member_modal: false,

			bookings_data: [],
			bookings_table_columns: [
				{
					label: 'Booking activity type',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Booking type',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Tool/Event',
					type: PAGINATED_TABLE_COLUMN_ACTION_LINK,
					action_event: 'go-to-tool-or-activity',
				},
				{
					label: 'Date',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Time',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
			],
			bookings_table_filters: [
				{
					slug: 'booking_type',
					options: [
						{ value: '', label: 'All types' },
						{ value: 'tool', label: 'Tool' },
						{ value: ACTIVITY_TYPE_MEMBER_EVENT, label: 'Member event' },
						{ value: ACTIVITY_TYPE_OPEN_EVENT, label: 'UCL event' },
						{ value: ACTIVITY_TYPE_PUBLIC_EVENT, label: 'Public event' },
						{ value: ACTIVITY_TYPE_TRAINING, label: 'Training' },
						{ value: ACTIVITY_TYPE_ORIENTATION, label: 'Orientation' },
						{ value: ACTIVITY_TYPE_NEW_MEMBER_INDUCTION, label: 'New member induction' },
					],
				},
			],

			audit_data: [],
			audit_table_columns: [
				{
					label: 'Action',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'By',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Time',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
			],
			audit_table_filters: [
				{
					slug: 'audit_type',
					options: [
						// These need to match modules/iom/enums/UserAction.php
						{ value: '', label: 'All types' },
						{ value: 'LoggedIn', label: 'Logged in' },
						{ value: 'LoggedOut', label: 'Logged out' },
						{ value: 'Registered', label: 'Registered' },
						{ value: 'Edited', label: 'Edited profile' },
						{ value: 'VerifiedEmail', label: 'Verified email' },
						{ value: 'VerifiedSms', label: 'Verified mobile phone no.' },
						{ value: 'ReceivedEmail', label: 'Received email' },
						{ value: 'ReceivedSms', label: 'Received SMS' },
						{ value: 'CompletedInduction', label: 'Completed new member induction' },
						{ value: 'RevokedInduction', label: 'Had new member induction revoked' },
						{ value: 'GrantedToolAccess', label: 'Had tool access granted' },
						{ value: 'RevokedToolAccess', label: 'Had tool access revoked' },
						{ value: 'SignedMemberAgreement', label: 'Signed member agreement' },
						{ value: 'JoinedWaitlist', label: 'Joined waiting list' },
						{ value: 'BookedActivity', label: 'Booked activity' },
						{ value: 'BookedTool', label: 'Booked tool' },
						{ value: 'GivenWarning', label: 'Given warning' },
						{ value: 'HadWarningRemoved', label: 'Had warning removed' },
						{ value: 'HadWarningsReset', label: 'Had warnings reset' },
					],
				},
			],
		};
	},
	computed: {
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.fullName === undefined
					|| this.model_data.fullName === ''
				)
			) {
				return 'New member';
			}
			return this.model_data.fullName;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Members' },
				{ label: 'All members', route: 'members__all_members' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'members__add_member':
					return CONTENT_MODE_ADD;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
		content_ready() {
			if ( this.content_mode === CONTENT_MODE_ADD ) {
				return true;
			}
			return Object.keys( this.model_data ).length > 0;
		},
		active_filters_bookings() {
			return [
				{
					name: 'booking_type',
					value: this.booking_type,
				},
			];
		},
		active_filters_audit() {
			return [
				{
					name: 'audit_type',
					value: this.audit_type,
				},
			];
		},
	},
	async mounted() {
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		await Promise.all( [
			this.$craftGraphqlApiClient.query(
				gql_query_location_grants_by_grantee,
				{ grantee_id: parseInt( this.$route.params.id, 10 ) }
			).then( ( response ) => {
				this.member_location_grants_data = this.convertMemberLocationGrantsData(
					response.data.locationGrantsByGrantee
				);
			} ),
			this.$craftGraphqlApiClient.query(
				gql_query_tool_class_grants_by_grantee,
				{ grantee_id: parseInt( this.$route.params.id, 10 ) }
			).then( ( response ) => {
				this.member_tool_class_grants_data = this.convertMemberToolAccessGrantsData(
					response.data.toolClassGrantsByGrantee
				);
			} ),
			this.$craftGraphqlApiClient.query(
				gql_query_member,
				{ id: this.$route.params.id }
			).then( ( response ) => {
				this.bookings_data = this.convertMemberBookingsData(
					response.data.user.tool_bookings,
					response.data.user.activity_bookings
				);
				this.member_warnings_data = this.convertMemberWarningsData(
					response.data.user.warnings
				);
				this.member_inductions_data = this.convertMemberInductionsData(
					response.data.user.inductions
				);
				this.model_data = this.convertMemberData( response.data.user );
			} ),
		] );
		// Need to wait for member_id before we show audit table.
		this.$craftGraphqlApiClient.query(
			gql_query_all_user_actions,
			{ user_id: parseInt( this.$route.params.id, 10 ) }
		).then( ( response ) => {
			this.audit_data = response.data.auditLogs.map( audit_log => {
				return [
					{
						visible: formatAuditLogMessage(
							audit_log.legacy_message,
							audit_log.action,
							JSON.parse( audit_log.metadata )
						),
						filterable: {
							slug: 'audit_type',
							values: [ audit_log.action ],
						}
					},
					{
						visible: audit_log.actor_id === null ? '[System]' : ( parseInt( audit_log.actor_id, 10 ) === parseInt( this.model_data.id, 10 ) ? '[Self]' : audit_log.actor_name ),
					},
					{
						visible: formatDateHuman( new Date( audit_log.dateCreated ) )
					},
					{
						visible: formatTime( new Date( audit_log.dateCreated ) )
					},
				];
			} );
		} );
		this.$craftGraphqlApiClient.query(
			gql_query_grantable_tool_classes
		).then( ( response ) => {
			this.grantable_tool_classes = convertCraftEntriesToSelectOptgroups(
				response.data.entries,
				() => true
			);
		} );
		this.$craftGraphqlApiClient.query(
			gql_query_grantable_locations
		).then( ( response ) => {
			this.grantable_locations = convertCraftEntriesToSelectOptgroups(
				response.data.entries,
				() => true
			);
		} );
		this.loading_state = LOADING_STATE_NONE;
	},
	methods: {
		formatDateHuman,
		isStudentMember() {
			return [
				'student_undergraduate',
				'student_postgraduate'
			].includes( this.model_data.membership_type );
		},
		convertMemberBookingsData( tool_bookings_data, activity_bookings_data ) {
			const bookings_data = [];
			bookings_data.push( ...tool_bookings_data.map( booking => {
				return [
					{
						visible: 'Tool',
						filterable: {
							slug: 'booking_type',
							values: [ 'tool' ],
						},
						tool_id: booking.tool.id, // For linking.
					},
					{
						visible: '-',
					},
					{
						visible: booking.tool.title,
					},
					{
						visible: formatDateHuman( new Date( booking.timeslot_begins ) ),
						sortable: formatDate( new Date( booking.timeslot_begins ) )
					},
					{
						visible: formatTimeslot( new Date( booking.timeslot_begins ), new Date( booking.timeslot_ends ) )
					},
				];
			} ) );
			bookings_data.push( ...activity_bookings_data.map( booking => {
				return [
					{
						visible: this.$craftSectionData.getEntryTypeName( 'activities', booking.activity.typeId ),
						filterable: {
							slug: 'booking_type',
							values: [ booking.activity.typeHandle ],
						},
						activity_id: booking.activity.id, // For linking.
					},
					{
						visible: booking.type === ACTIVITY_BOOKING_TYPE_WAITLIST ? 'Waiting list' : 'Attendee',
					},
					{
						visible: booking.activity.title,
					},
					{
						visible: formatDateHuman( new Date( booking.activity.start_date ) ),
						sortable: formatDate( new Date( booking.activity.start_date ) )
					},
					{
						visible: booking.activity.end_time
							? formatTimeslot(
								new Date( booking.activity.start_time ),
								new Date( booking.activity.end_time )
							)
							: formatTime( new Date( booking.activity.start_time ) )
					},
				];
			} ) );
			bookings_data.sort( ( a, b ) => {
				if ( a[2].sortable > b[2].sortable ) {
					return -1;
				}
				if ( a[2].sortable < b[2].sortable ) {
					return 1;
				}
				return 0;
			} );
			return bookings_data;
		},
		convertMemberWarningsData( data ) {
			return data.map( warning => {
				return [
					{
						visible: warning.issued_by_name,
					},
					{
						visible: formatDateHuman( new Date( warning.dateCreated ) ),
					},
					{
						visible: warning.reason,
					},
					{
						visible: null,
						warning_id: warning.id,
					}
				];
			} );
		},
		convertMemberInductionsData( data ) {
			return data.map( induction => {
				return [
					{
						visible: induction.location_name ?? '-',
					},
					{
						visible: induction.issued_by_name ?? '-',
					},
					{
						visible: formatDateHuman( new Date( induction.dateCreated ) ),
					},
					{
						visible: induction.date_agreement_signed === null ? 'Not signed' : formatDateHuman( new Date( induction.date_agreement_signed ) ),
					},
					{
						visible: null,
						induction_id: induction.id,
					}
				];
			} );
		},
		convertMemberLocationGrantsData( data ) {
			return data.map( grant => {
				return [
					{
						visible: grant.location_name,
					},
					{
						visible: grant.grantor_name ?? '-',
					},
					{
						visible: formatDateHuman( new Date( grant.dateCreated ) )
					},
					{
						visible: null,
						grant_id: grant.id,
					}
				];
			} );
		},
		convertMemberToolAccessGrantsData( data ) {
			return data.map( grant => {
				return [
					{
						visible: grant.tool_class_name + ( grant.tool_class_type === TOOL_CLASS_TYPE_PARENT_CLASS ? ' (all)' : '' ),
					},
					{
						visible: grant.grantor_name ?? '-',
					},
					{
						visible: formatDateHuman( new Date( grant.dateCreated ) )
					},
					{
						visible: null,
						grant_id: grant.id,
					}
				];
			} );
		},
		convertMemberData( data ) {
			data.dateCreated = new Date( data.dateCreated );
			data.tool_bookings = null; // Unset this, we handle it elsewhere.
			data.activity_bookings = null; // Unset this, we handle it elsewhere.
			data.inductions = null; // Unset this, we handle it elsewhere.
			return data;
		},
		saveMemberData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			this.$craftActionApiClient.query(
				'users/save-user',
				{
					// To add something, we just submit the edit form without an ID.
					userId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sendActivationEmail: this.content_mode === CONTENT_MODE_EDIT ? 0 : 1,
					age_gate: 1, // Assume user is of age.
					email: this.model_data.email,
					firstName: this.model_data.firstName,
					lastName: this.model_data.lastName,
					fields: {
						gender_identity: this.model_data.gender_identity,
						gender_identity_other: this.model_data.gender_identity_other,
						mobile_phone_number: this.model_data.mobile_phone_number,
						membership_type: this.model_data.membership_type,
						degree_type: this.model_data.degree_type,
						degree_name_position: this.model_data.degree_name_position,
						faculty: this.model_data.faculty,
						role: this.model_data.role,
						upi_code: this.model_data.upi_code,
						card_expiry_month: this.model_data.card_expiry_month,
						card_expiry_year: this.model_data.card_expiry_year,
					}
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'members__all_members',
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_member,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertMemberData( response.data.user );
					this.$router.push( {
						name: 'members__edit_member',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		refreshMemberData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.$craftGraphqlApiClient.query(
				gql_query_member,
				{ id: this.$route.params.id }
			).then( ( response ) => {
				this.bookings_data = this.convertMemberBookingsData(
					response.data.user.tool_bookings,
					response.data.user.activity_bookings
				);
				this.member_warnings_data = this.convertMemberWarningsData(
					response.data.user.warnings
				);
				this.member_inductions_data = this.convertMemberInductionsData(
					response.data.user.inductions
				);
				this.model_data = this.convertMemberData( response.data.user );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		refreshMemberLocationGrantsData() {
			this.$craftGraphqlApiClient.query(
				gql_query_location_grants_by_grantee,
				{ grantee_id: parseInt( this.$route.params.id, 10 ) }
			).then( ( response ) => {
				this.member_location_grants_data = this.convertMemberLocationGrantsData(
					response.data.locationGrantsByGrantee
				);
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		refreshMemberToolClassGrantsData() {
			this.$craftGraphqlApiClient.query(
				gql_query_tool_class_grants_by_grantee,
				{ grantee_id: parseInt( this.$route.params.id, 10 ) }
			).then( ( response ) => {
				this.member_tool_class_grants_data = this.convertMemberToolAccessGrantsData(
					response.data.toolClassGrantsByGrantee
				);
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		impersonateMember() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_impersonate_modal = false;
			this.$craftActionApiClient.query(
				'users/impersonate',
				{ userId: this.model_data.id }
			).then( response => {
				window.location = response.returnUrl;
			} );
		},
		async deleteMember() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			await this.$craftActionApiClient.query(
				'iom/member/delete-member',
				{
					user_id: this.model_data.id,
				}
			);
			this.$router.push( {
				name: 'members__all_members',
			} );
		},
		async inductMember() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_induct_member_modal = false;
			await this.$craftActionApiClient.query(
				'iom/member/induct-member',
				{
					user_id: this.model_data.id,
					location_id: this.induction_location === ''
						? null : this.induction_location,
				}
			);
			this.refreshMemberData();
			this.refreshMemberLocationGrantsData();
		},
		async grantLocationAccess() {
			if ( this.location_to_grant === '' ) {
				return;
			}
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/grant-location-access',
				{
					grantee_id: this.model_data.id,
					location_id: this.location_to_grant,
				}
			);
			this.refreshMemberLocationGrantsData();
		},
		async grantToolClassAccess() {
			if ( this.tool_class_to_grant === '' ) {
				return;
			}
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/grant-tool-class-access',
				{
					grantee_id: this.model_data.id,
					tool_class_id: this.tool_class_to_grant,
				}
			);
			this.refreshMemberToolClassGrantsData();
		},
		sendPasswordResetEmail() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_password_reset_modal = false;
			this.$craftActionApiClient.query(
				'users/send-password-reset-email',
				{
					userId: this.model_data.id,
				}
			).then( () => {
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		updateMemberStatus() {
			if ( this.content_mode !== CONTENT_MODE_EDIT ) {
				return;
			}
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			this.$craftActionApiClient.query(
				'users/save-user',
				{
					// To add something, we just submit the edit form without an ID.
					userId: this.model_data.id,
					fields: {
						member_status: this.model_data.member_status,
					}
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_member,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertMemberData( response.data.user );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		async removeWarning( row ) {
			if ( !confirm( 'Are you sure you want to remove this warning?' ) ) {
				return;
			}
			const warning_id = row[3].warning_id;
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/remove-warning',
				{
					warning_id: warning_id,
				}
			);
			this.refreshMemberData();
		},
		async resetWarnings() {
			if ( !confirm( 'Are you sure you want to reset this member’s warnings to zero?' ) ) {
				return;
			}
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/reset-warnings',
				{
					user_id: this.model_data.id,
				}
			);
			this.refreshMemberData();
		},
		async revokeInductionStatus( row ) {
			if ( !confirm( 'Are you sure you want to revoke this induction?' ) ) {
				return;
			}
			const induction_id = row[4].induction_id;
			this.loading_state = LOADING_STATE_OVERLAY;
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/revoke-induction',
				{
					induction_id: induction_id,
				}
			);
			this.refreshMemberData();
		},
		async revokeLocationGrant( row ) {
			if ( !confirm( 'Are you sure you want to revoke this access?' ) ) {
				return;
			}
			const grant_id = row[3].grant_id;
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/revoke-location-grant',
				{
					grant_id: grant_id,
				}
			);
			this.refreshMemberLocationGrantsData();
		},
		async revokeToolClassGrant( row ) {
			if ( !confirm( 'Are you sure you want to revoke this access?' ) ) {
				return;
			}
			const grant_id = row[3].grant_id;
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/revoke-tool-class-grant',
				{
					grant_id: grant_id,
				}
			);
			this.refreshMemberToolClassGrantsData();
		},
		goToToolOrActivity( row ) {
			if ( row[0].tool_id ) {
				this.$router.push( {
					name: 'tools__edit_tool',
					params: {
						id: row[0].tool_id,
					},
				} );
			} else {
				this.$router.push( {
					name: 'bookings__edit_activity',
					params: {
						id: row[0].activity_id,
					},
				} );
			}
		},
	},
};

</script>
