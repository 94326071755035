
<template>
	<div class="booking">
		<h2
			v-if="already_booked"
			class="booking__title"
		>
			You’re attending this event
		</h2>
		<h2
			v-else-if="already_booked_waitlist"
			class="booking__title"
		>
			You’re on the waiting list for this event
		</h2>
		<h2
			v-else-if="!viewIsSuccess()"
			class="booking__title"
		>
			Join this event
		</h2>
		<template v-if="viewIsLoading()">
			Loading...
		</template>
		<template v-else-if="viewIsSuccess()">
			<template v-if="capacity_available">
				<div class="booking__confirmation">
					<p>Your booking has been confirmed, you’ll receive an email shortly with additional details.</p>
					<a
						:href="backButtonUrl"
						class="button button--outline button--center"
					>{{ backButtonLabel }}</a>
				</div>
			</template>
			<template v-else>
				<div class="booking__confirmation">
					<p>You have been added to the waiting list for this event, you’ll receive an email shortly with additional details.</p>
					<a
						:href="backButtonUrl"
						class="button button--outline button--center"
					>{{ backButtonLabel }}</a>
				</div>
			</template>
		</template>
		<template v-else-if="viewIsConfirmBooking()">
			<div class="booking__detail">
				<dl class="booking__detail-row">
					<dt>Event</dt>
					<dd>{{ activityTitle }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Your name</dt>
					<dd>{{ craft_user_data.fullName }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Location</dt>
					<dd>{{ activityLocationName }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Date</dt>
					<dd>{{ activityDate }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Time</dt>
					<dd>{{ activityTime }}</dd>
				</dl>
			</div>
			<div class="form__set booking__agreement">
				<div class="form__checkbox form__checkbox--no-padding">
					<input
						id="booking-widget-member-agreement"
						v-model="agrees_to_agreement"
						type="checkbox"
					>
					<label for="booking-widget-member-agreement">
						I agree to the
						<a href="/members/member-agreement">
							Member Agreement
						</a>
						of Institute of Making
					</label>
				</div>
			</div>
			<div
				v-if="!capacity_available"
				class="form__set booking__agreement"
			>
				<div class="form__checkbox form__checkbox--no-padding">
					<input
						id="booking-widget-waitlist-confirmation"
						v-model="understands_waiting_list"
						type="checkbox"
					>
					<label for="booking-widget-waitlist-confirmation">
						I understand that this is not a booking, I’m adding myself to the waiting list.
					</label>
				</div>
			</div>
			<button
				class="button button--fullwidth button--invert button--center"
				:disabled="!booking_can_be_made"
				@click="bookActivity"
			>
				{{ capacity_available ? 'Confirm booking' : 'Join waiting list' }}
			</button>
		</template>
		<template v-else-if="viewIsError()">
			<div class="booking__message">
				<h3 class="booking__heading booking__heading--warning">
					Booking failed
				</h3>
				<p
					v-for="error, index in error_messages"
					:key="index"
				>
					{{ error }}
				</p>
			</div>
			<button
				class="button button--fullwidth button--invert button--center"
				@click="resetErrorState"
			>
				Go back
			</button>
		</template>
		<template v-else>
			<div class="booking__meta">
				<div v-if="activityLocationName">
					<h3 class="booking__meta-title">
						Location
					</h3>
					<div
						class="booking__event-data booking__event-data--location"
					>
						<a
							:href="activityLocationUrl"
							class="a"
						>
							{{ activityLocationName }}
						</a>
					</div>
				</div>

				<div>
					<h3 class="booking__meta-title">
						Date
					</h3>
					<div class="booking__event-data booking__event-data--date">
						{{ activityDate }}
					</div>
				</div>

				<div>
					<h3 class="booking__meta-title">
						Time
					</h3>
					<div class="booking__event-data booking__event-data--time">
						{{ activityTime }}
					</div>
				</div>
			</div>
			<template v-if="already_booked">
				<button
					class="button button--fullwidth button--danger button--center"
					@click="cancelBooking"
				>
					Cancel your booking
				</button>
			</template>
			<template v-else-if="already_booked_waitlist">
				<div class="flex flex--column flex--gap-small">
					<template v-if="capacity_available">
						<button
							class="button button--fullwidth button--invert button--center"
							@click="setViewToConfirmBooking"
						>
							Convert to booking
						</button>
					</template>
					<button
						class="button button--fullwidth button--danger button--center"
						@click="cancelWaitlistBooking"
					>
						Cancel your waiting list place
					</button>
				</div>
			</template>
			<template v-else>
				<template v-if="capacity_available">
					<button
						class="button button--fullwidth button--invert button--center"
						@click="setViewToConfirmBooking"
					>
						Book now
					</button>
				</template>
				<template v-else-if="waitlist_available">
					<div class="booking__message">
						<h3 class="booking__heading booking__heading--info">
							Waiting list active
						</h3>
						<p>This event is fully booked. You can add yourself to the waiting list below. If someone cancels their place, everyone on the waiting list will be sent an email, the person who books first gets the place.</p>
					</div>
					<button
						class="button button--fullwidth button--invert button--center"
						@click="setViewToConfirmBooking"
					>
						Join the waiting list
					</button>
				</template>
				<template v-else>
					<div class="booking__message">
						<h3 class="booking__heading booking__heading--warning">
							Event fully booked
						</h3>
						<p>This event is now fully booked.</p>
						<p>Keep an eye on our events page for new events that are added regularly.</p>
					</div>
					<a
						:href="backButtonUrl"
						class="button button--fullwidth button--invert button--center"
					>
						{{ backButtonLabel }}
					</a>
				</template>
			</template>
		</template>
	</div>
</template>

<script>

import { storeToRefs } from 'pinia';

import { useUserStore } from '../shared/stores/user';

import {
	ACTIVITY_BOOKING_TYPE_REGULAR,
	ACTIVITY_BOOKING_TYPE_WAITLIST,
} from '../../constants.js';

const VIEW_INITIAL = 'initial';
const VIEW_CONFIRM_BOOKING = 'confirm_booking';
const VIEW_LOADING = 'loading';
const VIEW_SUCCESS = 'success';
const VIEW_ERROR = 'error';

export default {
	props: {
		activityId: {
			required: true,
			type: String,
		},
		activityTitle: {
			required: true,
			type: String,
		},
		activityDate: {
			required: true,
			type: String,
		},
		activityTime: {
			required: true,
			type: String,
		},
		activityLocationName: {
			required: true,
			type: String,
		},
		activityLocationUrl: {
			required: true,
			type: String,
		},
		alreadyBooked: {
			required: false,
			type: Boolean,
			default: false,
		},
		alreadyBookedWaitlist: {
			required: false,
			type: Boolean,
			default: false,
		},
		capacityAvailable: {
			required: false,
			type: Boolean,
			default: false,
		},
		waitlistAvailable: {
			required: false,
			type: Boolean,
			default: false,
		},
		backButtonUrl: {
			required: true,
			type: String,
		},
		backButtonLabel: {
			required: true,
			type: String,
		},
	},
	setup() {
		const user_store = useUserStore();
		user_store.loadSession();
		const { craft_user_data } = storeToRefs( user_store );
		return { craft_user_data };
	},
	data() {
		return {
			// We need to be able to mutate availablity values after
			// initialisation, so let's turn them into data items.
			already_booked: this.alreadyBooked,
			already_booked_waitlist: this.alreadyBookedWaitlist,
			capacity_available: this.capacityAvailable,
			waitlist_available: this.waitlistAvailable,

			current_view: VIEW_INITIAL,

			error_messages: [],

			agrees_to_agreement: false,
			understands_waiting_list: false,
		};
	},
	computed: {
		booking_can_be_made() {
			return this.agrees_to_agreement
				&& ( this.capacity_available || this.understands_waiting_list );
		},
	},
	methods: {
		async bookActivity() {
			if ( !this.booking_can_be_made ) {
				return;
			}
			this.current_view = VIEW_LOADING;
			const booking_type = this.capacity_available ? ACTIVITY_BOOKING_TYPE_REGULAR : ACTIVITY_BOOKING_TYPE_WAITLIST;
			this.$craftActionApiClient.query(
				'iom/booking/save-member-activity-booking',
				{
					activity_id: this.activityId,
					type: booking_type,
				}
			).then( () => {
				this.current_view = VIEW_SUCCESS;
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.current_view = VIEW_ERROR;
				if ( error.response.data?.already_booked ) {
					this.already_booked = true;
				}
				if ( error.response.data?.already_booked_waitlist ) {
					this.already_booked_waitlist = true;
				}
				if ( error.response.data?.fully_booked ) {
					this.capacity_available = false;
				}
				if ( error.response.data?.fully_booked_waitlist ) {
					this.waitlist_available = false;
				}
				if ( !error.response.data?.errors ) {
					// Shouldn't happen.
					return;
				}
				this.error_messages = Object.values( error.response.data.errors ).reduce(
					( item, acc ) => {
						item.forEach( message => {
							acc.push( message );
						} );
						return acc;
					},
					[]
				);
			} );
		},
		async cancelBooking() {
			if ( !this.already_booked ) {
				return;
			}
			if ( !confirm( 'Are you sure you want to cancel this booking?' ) ) {
				return;
			}
			this.current_view = VIEW_LOADING;
			await this.$craftActionApiClient.query(
				'iom/booking/cancel-member-activity-booking',
				{
					activity_id: this.activityId,
				}
			);
			this.already_booked = false;
			this.agrees_to_agreement = false;
			this.understands_waiting_list = false;
			this.current_view = VIEW_INITIAL;
		},
		async cancelWaitlistBooking() {
			if ( !this.already_booked_waitlist ) {
				return;
			}
			if ( !confirm( 'Are you sure you want to cancel this waiting list booking?' ) ) {
				return;
			}
			this.current_view = VIEW_LOADING;
			await this.$craftActionApiClient.query(
				'iom/booking/cancel-member-activity-waitlist-booking',
				{
					activity_id: this.activityId,
				}
			);
			this.already_booked_waitlist = false;
			this.agrees_to_agreement = false;
			this.understands_waiting_list = false;
			this.current_view = VIEW_INITIAL;
		},
		viewIsConfirmBooking() {
			return this.current_view === VIEW_CONFIRM_BOOKING;
		},
		viewIsSuccess() {
			return this.current_view === VIEW_SUCCESS;
		},
		viewIsLoading() {
			return this.current_view === VIEW_LOADING;
		},
		viewIsError() {
			return this.current_view === VIEW_ERROR;
		},
		setViewToConfirmBooking() {
			this.current_view = VIEW_CONFIRM_BOOKING;
		},
		resetErrorState() {
			this.error_messages = [];
			this.current_view = VIEW_INITIAL;
		},
	},
};

</script>
